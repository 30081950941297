import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import NotFoundContent from '../components/NotFoundContent';
import HeroBanner from '../components/HeroBanner';

const NotFoundPage = () => (
  <Layout page="404">
    <SEO title="Page not found" description="No such page on Cybertent website" keywords={['404']} />
    <HeroBanner title="Page not found" subtitle="Page you are looking for has not been found" />
    <NotFoundContent />
  </Layout>
)

export default NotFoundPage
