import React from 'react';
import { Link } from 'gatsby';
import styles from "./notFound.module.scss";

const NotFoundContent = () => (
  <div className={styles.wrapper}>
    <h2>The page you are looking for seems to be not here.</h2>
    <Link to="/">Go back to home page</Link>
  </div>
)

export default NotFoundContent;
